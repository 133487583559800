import React, { useState } from 'react';
import logo from './assets/images/logo.png';
import supported from './assets/images/supported.png';
function App() {
	const [verifyEmail, setVerifyEmail] = useState(false);
	const [verifyNumber, setVerifyNumber] = useState(false);
	return (
		<>
			<header class='py-2 bg-white px-5 md:px-24'>
				<img src={logo} alt='' class='w-[100px] md:w-[120px]' />
			</header>
			<div class='min-h-screen flex flex-col items-center justify-center bg-[#121212] bg-[url("./assets/images/bg.png")] bg-fixed bg-no-repeat bg-center bg-cover py-10'>
				<h1 class='pb-10 text-lg md:text-3xl text-white px-5'>
					Lorem Ipsum is simply dummy text of the printing and typesetting industry.
				</h1>
				<form class='w-full md:w-3/4 lg:w-1/2 py-10 space-y-3 px-4 md:px-20 h-full flex flex-col justify-start overflow-hidden border border-white/10 bg-white/5 backdrop-blur-md rounded-2xl'>
					<div class='flex w-full items-start flex-col md:flex-row gap-3'>
						<div class='w-full space-y-2'>
							<label for='firstname' class='text-white text-sm'>
								First Name
							</label>
							<input
								id='firstname'
								type='text'
								name='firstname'
								required
								class='form-input text-base px-2.5 py-2 bg-white/10 text-white rounded-md w-full focus:outline-none border focus:border-white/50 border-white/10'
								placeholder=' '
							/>
						</div>
						<div class='w-full space-y-2'>
							<label for='lastname' class='text-white text-sm'>
								Last Name
							</label>
							<input
								id='lastname'
								type='text'
								name='lastname'
								required
								class='form-input text-base px-2.5 py-2 bg-white/10 text-white rounded-md w-full focus:outline-none border focus:border-white/50 border-white/10'
								placeholder=' '
							/>
						</div>
					</div>
					{/* <div class='w-full space-y-2'>
						<label for='email' class='text-white text-sm'>
							Email ID
						</label>
						<input
							id='email'
							type='email'
							name='email'
							required
							class='form-input text-base font-light px-2.5 py-2 bg-white/10 text-white rounded-md w-full focus:outline-none focus:border-white/50 border border-white/10'
							placeholder=' '
						/>
					</div> */}
					<div class='w-full space-y-2'>
						<label for='verify-email' class='text-white text-sm'>
							Email ID
						</label>
						<div class='relative'>
							<input
								id='verify-email'
								type='email'
								name='verify-email'
								required
								class='form-input text-base px-2.5 py-2 bg-white/10 text-white rounded-md w-full focus:outline-none focus:border-white/50 border border-white/10'
								placeholder=' '
							/>
							<div
								onClick={() => setVerifyEmail(!verifyEmail)}
								class='absolute top-1/2 cursor-pointer -translate-y-1/2 right-2 text-sm text-[#BEBEBE]'
							>
								<span>Verify</span>

								{/* <svg
										class='fill-green-600'
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										width='24'
										height='24'
									>
										<path fill='none' d='M0 0H24V24H0z' />
										<path d='M12 1l8.217 1.826c.457.102.783.507.783.976v9.987c0 2.006-1.003 3.88-2.672 4.992L12 23l-6.328-4.219C4.002 17.668 3 15.795 3 13.79V3.802c0-.469.326-.874.783-.976L12 1zm0 2.049L5 4.604v9.185c0 1.337.668 2.586 1.781 3.328L12 20.597l5.219-3.48C18.332 16.375 19 15.127 19 13.79V4.604L12 3.05zm4.452 5.173l1.415 1.414L11.503 16 7.26 11.757l1.414-1.414 2.828 2.828 4.95-4.95z' />
									</svg> */}
							</div>
						</div>
						{verifyEmail ? (
							<div class='w-1/2 flex items-center gap-3'>
								<input
									class='form-input text-center text-base font-light px-2.5 py-2 bg-white/10 text-white rounded-md w-10 focus:outline-none focus:border-white/50 border border-white/10'
									type='text'
									maxlength='1'
								/>
								<input
									class='form-input text-center text-base font-light px-2.5 py-2 bg-white/10 text-white rounded-md w-10 focus:outline-none focus:border-white/50 border border-white/10'
									type='text'
									maxlength='1'
								/>
								<input
									class='form-input text-center text-base font-light px-2.5 py-2 bg-white/10 text-white rounded-md w-10 focus:outline-none focus:border-white/50 border border-white/10'
									type='text'
									maxlength='1'
								/>
								<input
									onChange={() => setVerifyEmail(!verifyEmail)}
									class='form-input text-center text-base font-light px-2.5 py-2 bg-white/10 text-white rounded-md w-10 focus:outline-none focus:border-white/50 border border-white/10'
									type='text'
									maxlength='1'
								/>
							</div>
						) : (
							<></>
						)}
					</div>
					<div class='w-full space-y-2'>
						<label for='mobile-number' class='text-white text-sm'>
							Mobile Number
						</label>
						<div class='relative'>
							<input
								id='mobile-number'
								type='number'
								name='mobile-number'
								required
								class='form-input text-base px-2.5 py-2 bg-white/10 text-white rounded-md w-full focus:outline-none focus:border-white/50 border border-white/10'
								placeholder=' '
							/>
							<div
								onClick={() => setVerifyNumber(!verifyNumber)}
								class='absolute top-1/2 cursor-pointer -translate-y-1/2 right-2 text-sm text-[#BEBEBE]'
							>
								<span>Verify</span>

								{/* <svg
										class='fill-green-600'
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										width='24'
										height='24'
									>
										<path fill='none' d='M0 0H24V24H0z' />
										<path d='M12 1l8.217 1.826c.457.102.783.507.783.976v9.987c0 2.006-1.003 3.88-2.672 4.992L12 23l-6.328-4.219C4.002 17.668 3 15.795 3 13.79V3.802c0-.469.326-.874.783-.976L12 1zm0 2.049L5 4.604v9.185c0 1.337.668 2.586 1.781 3.328L12 20.597l5.219-3.48C18.332 16.375 19 15.127 19 13.79V4.604L12 3.05zm4.452 5.173l1.415 1.414L11.503 16 7.26 11.757l1.414-1.414 2.828 2.828 4.95-4.95z' />
									</svg> */}
							</div>
						</div>
						{verifyNumber ? (
							<div class='w-1/2 flex items-center gap-3'>
								<input
									class='form-input text-center text-base font-light px-2.5 py-2 bg-white/10 text-white rounded-md w-10 focus:outline-none focus:border-white/50 border border-white/10'
									type='text'
									maxlength='1'
								/>
								<input
									class='form-input text-center text-base font-light px-2.5 py-2 bg-white/10 text-white rounded-md w-10 focus:outline-none focus:border-white/50 border border-white/10'
									type='text'
									maxlength='1'
								/>
								<input
									class='form-input text-center text-base font-light px-2.5 py-2 bg-white/10 text-white rounded-md w-10 focus:outline-none focus:border-white/50 border border-white/10'
									type='text'
									maxlength='1'
								/>
								<input
									class='form-input text-center text-base font-light px-2.5 py-2 bg-white/10 text-white rounded-md w-10 focus:outline-none focus:border-white/50 border border-white/10'
									type='text'
									maxlength='1'
								/>
							</div>
						) : (
							<></>
						)}
					</div>
					<div class='w-full space-y-2'>
						<label for='mobile-number' class='text-white text-sm'>
							From the list of financial entities, vote for..
						</label>
						<select
							name=''
							id=''
							class='form-input text-base px-2.5 py-2.5 bg-white/10 text-white rounded-md w-full focus:outline-none focus:border-white/50 border border-white/10'
						>
							<option value='Best remittance service (Instant)'>Best remittance service (Instant)</option>
							<option value='Best Customer Service in Branch'>Best Customer Service in Branch</option>
							<option value='Best Call Center Service'>Best Call Center Service</option>
							<option value='Best Mobile App'>Best Mobile App</option>
							<option value='Best WPS Service provider'>Best WPS Service provider</option>
							<option value='Best Prepaid Card'>Best Prepaid Card</option>
						</select>
					</div>
					<div class='w-full space-y-2'>
						<label for='mobile-number' class='text-white text-sm'>
							Select Company{' '}
						</label>
						<select
							name=''
							id=''
							class='form-input text-base px-2.5 py-2.5 bg-white/10 text-white rounded-md w-full focus:outline-none focus:border-white/50 border border-white/10'
						>
							<option value='Company Name1'>Company Name1</option>
							<option value='Company Name2'>Company Name2</option>
							<option value='Company Name3'>Company Name3</option>
							<option value='Company Name4'>Company Name4</option>
						</select>
					</div>
					<div class='flex items-center pt-8'>
						<input
							id='Agree'
							type='checkbox'
							value=''
							class='w-4 h-4 accent-[#F2672E] bg-white/10 rounded'
						/>
						<label for='Agree' class='ml-2 text-sm font-light text-white'>
							I Agree to Terms and Conditions
						</label>
					</div>
					<div class='pt-3'>
						<button type='Submit' class='text-sm text-white bg-[#F2672E] w-32 py-2.5 rounded-md'>
							Submit
						</button>
					</div>
				</form>
				<div class='py-10 space-y-5'>
					<div class=''>
						<h1 class='text-white text-xl font-semibold text-center'>Supported By</h1>
					</div>
					<div class='flex items-center justify-center flex-wrap   gap-5'>
						<a href='#'>
							<img src={supported} alt='' width='140px' class='rounded-md' />
						</a>
						<a href='#'>
							<img src={supported} alt='' width='140px' class='rounded-md' />
						</a>
						<a href='#'>
							<img src={supported} alt='' width='140px' class='rounded-md' />
						</a>
						<a href='#'>
							<img src={supported} alt='' width='140px' class='rounded-md' />
						</a>
					</div>
				</div>
				<div class='w-10/12 pt-8'>
					<p class='text-white text-center font-light text-sm'>
						Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
						the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
						of type and scrambled it to make a type specimen book.
					</p>
				</div>
			</div>
		</>
	);
}

export default App;
